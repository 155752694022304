import { QueryKeysEnum } from 'models/enums';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	getPricesForProductV2,
	getActiveIngredient,
	getActiveIngredientNotes,
	getActiveIngredients,
	// getProductNotes,
	getProductPresentationDetails,
	getProductPresentations,
	getProductRegistrations,
	getProductRegistration,
	getProducts,
	postActiveIngredientNotes,
	postProductNotes,
	// getTenders,
	getProductTenders,
	getProductShortageInfo,
} from 'services';
import { getDashboardPriceChanges, getProductRegistrationNotes } from 'services';

// PRODCUT
export const useProducts = ({ queryParams }, enabled) => {
	const { company, atc_code, ordering, page, page_size, search } = queryParams;

	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.PRODUCT_PRESENTATION,
			company,
			atc_code,
			ordering,
			page,
			page_size,
			search,
		],
		() => getProducts({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useProductPresentation = ({ params }) => {
	const { id } = params;

	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.PRODUCT_PRESENTATION, id],
		() => getProductPresentationDetails({ params }),
		{ enabled: !!id }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useProductPresentations = ({ queryParams }, enabled) => {
	const {
		country__in,
		company__in,
		product_pack__presentation__in,
		product_pack__pack_size__in,
		product_pack__active_ingredients__strength__in,
		ingredient_and_strengths,
		active_ingredient__in,
		active_ingredient,
		atc_code,
		exclude_product_id,
		is_commercial_exclude,
		is_price_empty,
		is_injectable,
		ordering,
		page,
		page_size,
		search,
	} = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.PRODUCT_PRESENTATIONS,
			country__in,
			company__in,
			product_pack__presentation__in,
			product_pack__pack_size__in,
			product_pack__active_ingredients__strength__in,
			ingredient_and_strengths,
			active_ingredient__in,
			active_ingredient,
			atc_code,
			exclude_product_id,
			is_commercial_exclude,
			is_price_empty,
			is_injectable,
			ordering,
			page,
			page_size,
			search,
		],
		() => getProductPresentations({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useProductRegistrations = ({ queryParams }, enabled) => {
	const {
		search_for,
		presentation,
		is_injectable,
		pack_size,
		strength,
		ordering,
		page,
		page_size,
		search,
	} = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.PRODUCT_REGISTRATIONS,
			search_for,
			presentation,
			is_injectable,
			pack_size,
			strength,
			ordering,
			page,
			page_size,
			search,
		],
		() => getProductRegistrations({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useProductRegistration = ({
	params,
	queryParams,
}: {
	params?: any;
	queryParams?: any;
}) => {
	const { id } = params;

	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.PRODUCT_PRESENTATION, id],
		() => getProductRegistration({ id, queryParams }),
		{ enabled: !!id }
	);
	return { data: data?.results[0], error, isLoading, isFetching, isFetched };
};

export const useProductShortageInfo = ({id, queryParams}: {id: number|string, queryParams?: any;}) => {

	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.PRUDUCT_SHORTAGE],
		() => getProductShortageInfo({ id, queryParams }),
		{ enabled: !!id }
	);
	return { data: data?.results[0], error, isLoading, isFetching, isFetched };
};

export const useProductTenders = ({ queryParams }, enabled) => {
	const {
		product,
		active_ingredient,
		active_ingredient__in,
		country__in,
		show_closed,
		ordering,
		page,
		page_size,
		search,
	} = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.PRODUCT_TENDERS,
			product,
			active_ingredient,
			active_ingredient__in,
			country__in,
			show_closed,
			ordering,
			page,
			page_size,
			search,
		],
		() => getProductTenders({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useProductPrices = ({ params, queryParams }, enabled) => {
	const { product_id } = params;
	const { ordering, page, page_size, search } = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.PRODUCT_PRICES,
			product_id,
			ordering,
			page,
			page_size,
			search,
		],
		() => getPricesForProductV2({ params, queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useProductNotes = ({ params, queryParams }, enabled) => {
	const { id } = params;
	const { ordering, country, page, page_size, search } = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.PRODUCT_NOTES,
			id,
			ordering,
			country,
			page,
			page_size,
			search,
		],
		() => getProductRegistrationNotes({ params, queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useDashboardPrices = ({ queryParams }, enabled) => {
	const { ordering, country, page, page_size, search, change_delta } = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.DASHBOARD_PRICES,
			ordering,
			country,
			page,
			page_size,
			search,
			change_delta,
		],
		() => getDashboardPriceChanges({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useMutateProductNotes = () => {
	const queryClient = useQueryClient();

	const postProductNote = useMutation(
		({ productId, noteData }: { productId: string; noteData: any }) =>
			postProductNotes(productId, noteData),
		{
			onSuccess: (updatedNote: any) => {
				queryClient.invalidateQueries({
					queryKey: [QueryKeysEnum.PRODUCT_NOTES],
					exact: false,
				});
			},
		}
	);
	return {
		postProductNotes: postProductNote.mutate,
		postProductNotesAsync: postProductNote.mutateAsync,
		postProductNotesLoading:
			postProductNote.isLoading || postProductNote.isLoading,
		postProductNotesError: postProductNote.error,
	};
};

// ACTIVE INGREDIENT

export const useActiveIngredient = (id) => {
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.ACTIVE_INGREDIENT, id],
		() => getActiveIngredient(id),
		{ enabled: !!id }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useActiveIngredients = ({ queryParams }, enabled) => {
	const { ordering, page, page_size, search } = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[QueryKeysEnum.ACTIVE_INGREDIENTS, ordering, page, page_size, search],
		() => getActiveIngredients({ queryParams }),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useActiveIngredientNotes = ({ params, queryParams }, enabled) => {
	const { id } = params;
	const { ordering, page, page_size, search } = queryParams;
	const { data, error, isLoading, isFetching, isFetched } = useQuery(
		[
			QueryKeysEnum.ACTIVE_INGREDIENT_NOTES,
			id,
			ordering,
			page,
			page_size,
			search,
		],
		() => getActiveIngredientNotes(params, queryParams),
		{ enabled }
	);
	return { data, error, isLoading, isFetching, isFetched };
};

export const useMutateActiveIngredientNotes = () => {
	const queryClient = useQueryClient();

	const postActiveIngredientNote = useMutation(
		({ uid, noteData }: { uid: string; noteData: any }) =>
			postActiveIngredientNotes(uid, noteData),
		{
			onSuccess: (updatedNote: any) => {
				queryClient.invalidateQueries({
					queryKey: [QueryKeysEnum.ACTIVE_INGREDIENT_NOTES],
					exact: false,
				});
			},
		}
	);
	return {
		postActiveIngredientNotes: postActiveIngredientNote.mutate,
		postActiveIngredientNotesAsync: postActiveIngredientNote.mutateAsync,
		postActiveIngredientNotesLoading:
			postActiveIngredientNote.isLoading || postActiveIngredientNote.isLoading,
		postActiveIngredientNotesError: postActiveIngredientNote.error,
	};
};
