import React from 'react';
import { ProductLinkCell } from 'components/Tables/shared/UniqueCells';
import { StatusColorEnum, TableCustomCellEnum } from 'models/enums';
import moment from 'moment';
import { COMMON_DATE_FORMAT } from 'utils/consts';

export const parallelColumns = [
	{
		header: 'Product Name',
		accessorKey: ['product_registration__pack__product__name', 'product__name'],
		accessorFn: (row) => row?.product_registration ? (
			<ProductLinkCell
				value={
					row?.product_registration?.pack?.product
						? row.product_registration.pack.product.name
						: null
				}
				className='capitalize'
				link={`/product-explorer/${row?.product_registration?.pack?.id}/`}
				params={
					!row.product_registration.is_eu_registered &&
          `?country=${row?.product_registration?.country?.id || '0'}`
				}
				extraProps={{
					minSize: 80,
					maxSize: 150,
				}}
			/>
		) : row?.product_name,
		cellType: TableCustomCellEnum.DATA,
		minSize: 80,
		maxSize: 150,
	},
	{
		header: 'Pack Size',
		accessorKey: 'product_registration__pack__pack_size__name',
		accessorFn: (row) =>
			row?.product_registration?.pack
				? row.product_registration.pack.pack_size?.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		size: 75,
		maxSize: 100,
	},
	{
		header: 'Strength',
		accessorKey: 'strength',
		accessorFn: (row) =>
			row?.product_registration?.pack
				? row.product_registration.pack.active_ingredients
					?.map((val) => val?.strength?.name)
					.join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		size: 100,
		maxSize: 130,
		enableSorting: true,
	},
	{
		header: 'MA Holder',
		accessorKey: 'product_registration__pack__product__company__name',
		accessorFn: (row) =>
			row?.product_registration?.pack?.product?.company
				? row.product_registration.pack.product.company.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		className: 'capitalize',
		minSize: 90,
		maxSize: 120
	},
	{
		header: 'Reg. Number',
		accessorKey: 'license_number',
		accessorFn: (row) => (row?.license_number ? row.license_number : null),
		cellType: TableCustomCellEnum.DATA,
		className: 'capitalize',
		minSize: 100,
		maxSize: 150,
	},
	{
		header: 'Parallel Distributor',
		accessorKey: 'company__name',
		accessorFn: (row) => (row?.company ? row.company.name : null),
		cellType: TableCustomCellEnum.DATA,
		className: 'capitalize',
		minSize: 100,
		maxSize: 150,
	},
	{
		header: 'Date of Issue',
		accessorKey: 'date_of_decision',
		accessorFn: (row) =>
			row.date_of_decision
				? moment(row.date_of_decision).format(COMMON_DATE_FORMAT)
				: '',
		cellType: TableCustomCellEnum.DATA,
		minSize: 80,
		maxSize: 130,
	},
	{
		header: 'Destination',
		accessorKey: 'destination_countries__name',
		accessorFn: (row) => {
			if(row.destination_countries){
				return row.destination_countries.length === 1 ? (
					<ProductLinkCell
						key={row.destination_countries[0].id}
						value={row.destination_countries[0].name}
						link={'/eu-wda-licenses/'}
						params={
							`s=${encodeURIComponent(JSON.stringify({
								countries: row.destination_countries
							}))}`
						}
					/>
				) : row.destination_countries.length > 1 ? (
					row.destination_countries.map((country) => country?.name)
						.join(',')
				) : '';
			}else{
				return '';
			}
		},
		cellType: TableCustomCellEnum.DATA,
		size: 100,
		maxSize: 140,
		enableSorting: false,
	},
	{
		header: 'Origin',
		accessorKey: 'origin_countries__name',
		accessorFn: (row) => {
			if(row.origin_countries){
				return row.origin_countries.length === 1 ? (
					<ProductLinkCell
						key={row.origin_countries[0].id}
						value={row.origin_countries[0].name}
						link={'/eu-wda-licenses/'}
						params={
							`s=${encodeURIComponent(JSON.stringify({
								countries: row.origin_countries
							}))}`
						}
					/>
				) : row.origin_countries.length > 1 ? (
					row.origin_countries.map((country) => country?.name)
						.join(',')
				) : '';
			}else{
				return '';
			}
		},
		cellType: TableCustomCellEnum.DATA,
		size: 100,
		maxSize: 140,
		enableSorting: false,
	},
	{
		header: 'Status',
		accessorKey: 'status',
		accessorFn: (row) =>
			row?.status
				? {
					label: row.status,
					color: StatusColorEnum[row.status?.toUpperCase()],
				}
				: null,
		cellType: TableCustomCellEnum.STATUS,
		size: 70,
		alignHeader: 'center',
		align: 'center',
	},
];
