import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Grid, Paper } from '@mui/material';

import { RemoteDataTable } from 'components/Tables';
import { FormWithAdditionaFilters } from 'components/Forms';

import { columns } from './columns';
import { searchFilters } from './searchFilters';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { useWDALicenses } from 'services/queries';
import { useSearchParams } from 'hooks';

interface SearchInputsIntreface {
    search: string;
    countries: { name: string; id: string; code: string | number }[];
    license_conditions: { name: string; id: number }[];
    only_from_database: boolean;
}

const initialSearchInputs = {
	search: '',
	countries: [],
	license_conditions: [],
	only_from_database: false,
};

export const WDALicenses = () => {
	const [
		searchInputs, 
		setSearchInputs, 
		resetSearchInputs
	] = useSearchParams<SearchInputsIntreface>(initialSearchInputs);

	const WDAQueryParams = useMemo(
		() => ({
			search: searchInputs.search,
			country__in: searchInputs.countries.map((val) => val.id).join(','),
			authorization__in: searchInputs.license_conditions
				.map((val) => val.id)
				.join(','),
			...(searchInputs.only_from_database && {
				is_profile_filled: searchInputs.only_from_database,
			}),
		}),
		[
			searchInputs.countries,
			searchInputs.license_conditions,
			searchInputs.only_from_database,
			searchInputs.search,
		]
	);

	const {
		data: WDAData,
		state: WDAState,
		setPagination: WDASetPagination,
		rowCount: WDARowCount,
		setSorting: WDASorting,
		pageCount: WDAPageCount,
		setPageCount: WDASetPageCount,
	} = useQueryPagination(
		useWDALicenses, 
		// searchInputs.search, 
		null,
		null, 
		WDAQueryParams
	);

	const onChangeHandler = useCallback((e) => setSearchInputs((prevInputs) => ({
		...prevInputs,
		[e.target.name]: e.target.value,
	})), [setSearchInputs]);

	return (
		<Paper elevation={0} sx={{borderRadius: '10px'}}>
			<RemoteDataTable
				tableId='true'
				state={WDAState}
				data={WDAData}
				pageCount={WDAPageCount}
				onRowsPerPageChange={WDASetPageCount}
				onPaginationChange={WDASetPagination}
				rowCount={WDARowCount}
				columns={columns}
				enableRowSelection={false}
				enablePagination
				manualSorting
				enableMultiSort={false}
				onSortingChange={WDASorting}
				renderCustomActions={({table}) => (
					<Grid container flexDirection={'column'} px={3}>
						<FormWithAdditionaFilters
							filters={searchFilters}
							searchInputs={searchInputs}
							onChangeHandler={onChangeHandler}
							gridWidth={2}
							onResetHandler={resetSearchInputs}
							fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
							isFullScreen={table.getState().isFullScreen}
							compact
							showReset
						/>
					</Grid>
				)}
			/>
		</Paper>
	);
};
