import { SortingType } from 'models/types';

export function getSortingValue(sortObject: SortingType[]) {
	if (sortObject.length > 0) {
		if (sortObject[0].id.includes(',')) {
			// multi sort by same desc attribute
			const splitSorts = sortObject[0].id.split(',');
			return splitSorts.map((item) => (sortObject[0].desc ? '-' : '') + item);
		} else {
			return (sortObject[0].desc ? '-' : '') + sortObject[0].id;
		}
	} else {
		return undefined;
	}
}

export function getSingleOrdering(sorting: SortingType[]) {

	return { ordering: getSortingValue(sorting) };
}

export function getMultipleOrdering(sorting: SortingType[]) {
	const sortingValues = sorting.map((sortObject) => getSortingValue([sortObject])).filter(Boolean) as string[];

	const ordering = sortingValues.join(',');

	return {
		ordering: ordering || undefined
	};
}
