import React from 'react';
import { Box, keyframes, Typography } from '@mui/material';
import { COLORS } from 'utils/consts';

const dotAnimation = keyframes`
  0% { opacity: 1; }
  33% { opacity: 0.5; }
  66% { opacity: 0.2; }
  100% { opacity: 1; }
`;

export const LoadingDots: React.FC = () => (
	<Box
		sx={{
			display: 'flex',
			alignItems: 'center',
			gap: '4px',
			padding: '5px 5px 5px 15px',
		}}
	>
		<Typography color={COLORS.black} fontStyle='italic' fontSize={14} lineHeight={20}>
			Loading
		</Typography>
		{[...Array(3)].map((_, index) => (
			<Box
				key={index}
				sx={{
					width: '3px',
					height: '3px',
					borderRadius: '50%',
					backgroundColor: COLORS.black,
					animation: `${dotAnimation} 1s ease-in-out infinite`,
					animationDelay: `${index * 0.2}s`, // delay for each dot
				}}
			/>
		))}
	</Box>
);
