import { useEffect, useMemo, useState } from 'react';
import { SortingType } from 'models/types';
import { getMultipleOrdering } from 'utils';
import { useDebounceObject } from '.';

export const useQueryPagination = (queryService, searchKeyword?, params?, queryParams?, options?) => {
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const [doneLoading, setDoneLoading] = useState(false);
	// const search = useDebounce(queryParams.search || searchKeyword, 1000);
	const debouncedQueryParams = useDebounceObject(queryParams, 500);

	const [data, setData] = useState<any[] | undefined>();

	const [rowCount, setRowCount] = useState(0);
	const [sorting, setSorting] = useState<SortingType[]>(options?.sort || []);
	const [pageCount, setPageCount] = useState<number | null>(null);
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

	const pageResetted = useMemo(() => {
		// when filters are being applied go back to first page
		if (pagination.pageIndex !== 0) {
			setPagination((prev) => ({ ...prev, pageIndex: 0 }));
		}
		return true;
	}, [debouncedQueryParams, params, pageCount]);

	const enabled = useMemo(() => {
		if (params && Object.values(params).some((val) => !val)) {
			return false;
		}
		if (options?.mandatory) {
			const queryParamsCheck =
				debouncedQueryParams.search != null
					? { searchKeyword: debouncedQueryParams.search, ...debouncedQueryParams }
					: { ...debouncedQueryParams };
			const shouldNotFetch =
				options?.mandatory.some((mandatoryOption) => !queryParamsCheck[mandatoryOption]) || !pageResetted;
			if (shouldNotFetch) return false;
			else {
				return true;
			}
		} else {
			return true;
		}
	}, [options?.mandatory, params, debouncedQueryParams]);

	const {
		data: dataResult,
		error,
		isLoading,
		isFetching,
		isFetched
	} = queryService(
		{
			params,
			queryParams: {
				// conditional ordering for common and price columns
				...getMultipleOrdering(sorting),
				page: pagination.pageIndex + 1,
				page_size: pageCount || 10,
				...debouncedQueryParams
			}
		},
		enabled
	);

	useEffect(() => {
		if (dataResult) {
			setData(dataResult?.results || []);
			setRowCount(dataResult?.count);
			setDoneLoading(true);
		}
	}, [dataResult]);

	useEffect(() => {
		if (rowCount === 0) return;
		const paramName = options?.tableId ? `p${options.tableId}` : 'p';
		const sortParamName = options?.tableId ? `sort${options.tableId}` : 'sort';
		const params = new URLSearchParams(window.location.search);
		const pageParam = params.get(paramName);
		const sortParam = params.get(sortParamName);
		const pageIndex = pageParam ? parseInt(pageParam, 10) - 1 : 0;
		const count = rowCount / pagination.pageSize;

		if (count && pageIndex > 0 && pageIndex < count) {
			setPagination((prev) => ({ ...prev, pageIndex }));
		} else if (!pageParam || pageIndex !== pagination.pageIndex) {
			setPagination((prev) => ({
				...prev,
				pageIndex: pageParam ? pageIndex : 0
			}));
		}

		if (sortParam) {
			const sortArray = sortParam.split(',').map((sort) => {
				const [id, desc] = sort.split(':');
				return { id, desc: desc === 'desc' };
			});
			setSorting(sortArray);
		}
	}, [window.location.search, window.history.state, rowCount, pagination.pageSize, options?.tableId]);

	useEffect(() => {
		if (isFirstLoad) {
			setIsFirstLoad(false);
		} else {
			const paramName = options?.tableId ? `p${options.tableId}` : 'p';
			const sortParamName = options?.tableId ? `sort${options.tableId}` : 'sort';
			// Update the URL with the new page and sort parameters
			const params = new URLSearchParams(window.location.search);
			if (pagination.pageIndex !== 0) {
				params.set(paramName, String(pagination.pageIndex + 1));
			} else {
				params.delete(paramName); // Remove p from URL if we're on the first page
			}

			if (sorting.length > 0) {
				const sortString = sorting.map((sort) => `${sort.id}:${sort.desc ? 'desc' : 'asc'}`).join(',');
				params.set(sortParamName, sortString);
			} else {
				params.delete(sortParamName); // Remove sort from URL if no sorting is applied
			}

			window.history.replaceState(null, '', `?${params.toString()}`);
		}
	}, [pagination, sorting, options?.tableId]);

	const multipleSorting = (sortFunction: () => SortingType[]) => {
		const updatedSorting = [...sorting];
		const incomeSorting = sortFunction()[0];

		const index = sorting.findIndex((sorting) => sorting.id === incomeSorting.id);

		if (index > -1) {
			if (updatedSorting[index].desc) {
				updatedSorting[index].desc = false; // toggle ASC
			} else {
				updatedSorting.splice(index, 1); // remove sorting
			}

			setSorting(updatedSorting);
		} else {
			setSorting([...updatedSorting, { ...incomeSorting, desc: true }]); // add DESC
		}
	};

	return {
		data,
		isLoading,
		isFetching,
		isFetched,
		error,
		setPagination,
		rowCount,
		pageCount,
		setPageCount,
		setSorting: multipleSorting,
		state: {
			isLoading: isLoading || isFetching,
			isError: error,
			pagination,
			showProgressBars: isLoading || isFetching || !doneLoading,
			sorting
		}
	};
};
