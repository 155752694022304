import React from 'react';
import { FileIcon, ProductIcon } from 'components/Icons';
import { ProductLinkCell } from 'components/Tables/shared/UniqueCells';
import { TableCustomCellEnum } from 'models/enums';

export const msrketingAuthorizationLicensesColumns = [
	{
		size: 200,
		header: 'Product',
		accessorKey: 'name',
		accessorFn: (row) => (
			<ProductLinkCell
				value={row.name}
				link={`/product-explorer/${row?.product_pack?.id}/`}
				params={!row.is_eu_authorized && `?country=${row?.country?.id || '0'}`}
				icon={<ProductIcon />}
			/>
		),
		cellType: TableCustomCellEnum.PRODUCT_LINK,
		align: 'right',
	},
	{
		size: 100,
		header: 'Common Name',
		accessorFn: (row) =>
			row.active_ingredients
				? row.active_ingredients
					.map((val) => val.active_ingredient.name)
					.join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
	{
		size: 100,
		header: 'Pack Size',
		accessorKey: 'pack_size__name',
		accessorFn: (row) => (row.pack_size ? row.pack_size.name : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		size: 100,
		header: 'Presentation',
		accessorKey: 'presentation__name',
		accessorFn: (row) => (row.presentation ? row.presentation.name : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		size: 100,
		header: 'Strength',
		accessorKey: 'strength',
		accessorFn: (row) =>
			row.active_ingredients
				? row.active_ingredients.map((val) => val.strength).join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: true,
	},
	{
		size: 100,
		header: 'Country',
		accessorKey: 'country__name',
		accessorFn: (row) => (row.country ? row.country.name : null),
		cellType: TableCustomCellEnum.COUNTRY,
		align: 'right',
	},
	{
		size: 100,
		header: 'MA Holder',
		accessorKey: 'ma_holder__name',
		accessorFn: (row) => (row.ma_holder ? row.ma_holder.name : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
];

export const PTLlListiongColumns = [
	{
		header: 'Product Name',
		accessorKey: 'product__name',
		accessorFn: (row) => (row.product ? row.product.name : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
	{
		header: 'Strength',
		accessorKey: 'strength',
		accessorFn: (row) =>
			row.eu_authorized_product?.active_ingredients
				? row.eu_authorized_product.active_ingredients
					.map((val) => val.strength)
					.join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: true,
	},
	{
		header: 'Pack Size',
		accessorKey: 'eu_authorized_product__pack_size__name',
		accessorFn: (row) =>
			row.eu_authorized_product?.pack_size
				? row.eu_authorized_product.pack_size.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
	{
		header: 'MA Holder',
		accessorKey: 'product__ma_holder__name',
		accessorFn: (row) =>
			row.product?.ma_holder ? row.product.ma_holder.name : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'EU Number',
		accessorKey: 'eu_authorized_product__eu_code',
		accessorFn: (row) =>
			row.eu_authorized_product ? row.eu_authorized_product.eu_code : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
	{
		header: 'Parallel Distrubutor',
		accessorKey: 'company__name',
		accessorFn: (row) => (row.company ? row.company.name : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Date of Issue',
		accessorKey: 'date_of_decision',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Destination Country',
		accessorFn: (row) =>
			row.destination_countries
				? row.destination_countries.map((val) => val.name).join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
	{
		header: 'Status',
		accessorKey: 'status',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
];

export const notesColumns = [
	{
		header: 'User Name',
		accessorKey: 'user_name',
		cellType: TableCustomCellEnum.AVATAR,
		align: 'right',
	},
	{
		header: 'Description',
		accessorKey: 'note',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Date',
		accessorKey: 'created_at',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Note',
		cellType: TableCustomCellEnum.ICON,
		icon: <FileIcon />,
		align: 'right',
	},
];
