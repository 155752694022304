import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Grid, Box, Paper } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { 
	BaseButton, 
	// ExpandButton, 
	SettingsButton 
} from 'components/Buttons';
import { COLORS } from 'utils/consts';
import { MaterialUISizeEnum } from 'models/enums';
import { MEDIUM_BORDER_RADIUS } from 'utils/consts/themeConsts';
import { CustomInput } from 'components/Inputs/CustomInput/CustomInput';
import { CustomInputTypeEnum } from 'models/enums/CustomInputTypeEnum';

interface FormWithAdditionaFiltersProps {
	parentValue?: string;
	filters: any[];
	searchInputs: any;
	onChangeHandler: (x: any) => void;
	onResetHandler?: () => void;
	fullScreenChangeHandler?: (x?: any) => void;
	isFullScreen?: boolean;
	children?: ReactNode;
	gridWidth?: number;
	additionalFilters?: ReactNode;
	compact?: boolean;
	showReset?: boolean;
}

export const FormWithAdditionaFilters: FC<FormWithAdditionaFiltersProps> = ({
	parentValue,
	filters,
	searchInputs,
	onChangeHandler,
	onResetHandler,
	// fullScreenChangeHandler,
	children,
	additionalFilters,
	gridWidth = 6,
	compact = false,
	showReset = false,
	// isFullScreen = false,
}) => {
	const [showFilters, setShowFilters] = useState(false);

	const appliedFiltersCount = useMemo(()=>(
		filters.filter(
			(e) => ![CustomInputTypeEnum.SWITCH, CustomInputTypeEnum.TEXT].includes(e.type)
		).reduce((acc, val) => {
			if (val.name === 'ingredient_and_strengths' && searchInputs[val.name]) {
				acc.push(searchInputs[val.name][0]?.ingredient ? {
					...searchInputs[val.name][0]
				} : null);
			} else if (searchInputs[val.name]) {
				acc.push(
					val.name === 'search'
						? {
							value: searchInputs[val.name],
							name: searchInputs[val.name],
						}
						: searchInputs[val.name].map((value) => ({
							...value,
						}))
				);
			}
			return acc;
		}, []).flat().filter((value) => value !== null).length
	),[filters, searchInputs]);

	return (
		<>
			{!compact && (
				<Grid item container mb={2} xs={12} spacing={2}>
					{children}
					<Grid ml='auto' item xs={12} md={gridWidth} display={'flex'}>
						<Box ml='auto' display={'flex'} alignItems={'center'}>
							{showFilters && (
								<Box>
									<BaseButton
										color='inherit'
										label={'Clear All'}
										onClick={onResetHandler}
										sx={{
											borderRadius: MEDIUM_BORDER_RADIUS,
											padding: '4px 16px'
										}}
									/>
								</Box>
							)}
							<Box ml={2}>
								<SettingsButton
									onClick={() => setShowFilters(!showFilters)}
									color={showFilters ? 'primary' : 'inherit'}
									size={MaterialUISizeEnum.SMALL}
									sx={{
										color: showFilters ? COLORS.darkGray : COLORS.white,
										backgroundColor: showFilters
											? COLORS.mediumLightGray
											: COLORS.blue,
										'&:hover': {
											backgroundColor: showFilters
												? '#f5f5f5'
												: COLORS.primaryDark,
										}
									}}
								>
									{showFilters ? 'hide filters' : 'more filters'}
									{appliedFiltersCount > 0 ? ' ('+appliedFiltersCount+')' : ''}
								</SettingsButton>
							</Box>
							{/* <Box ml={2}>
								<ExpandButton
									onClick={fullScreenChangeHandler}
									isExpanded={isFullScreen}
								/>
							</Box> */}
						</Box>
					</Grid>
				</Grid>
			)}
			<Paper 
				elevation={0} 
				sx={{
					padding: compact ? '20px 0' : '20px', 
					borderRadius: '10px',
					marginBottom: '20px',
					width: '100%',
					// hides/shows filters instead of rerendering to prevent extra api calls
					...(!(showFilters || compact) && {display: 'none'})
				}}
			>
				{/* {!compact && <Divider light={true} />} */}
				<Grid
					item
					container
					xs={12}
					spacing={2}
					alignItems={'end'}
				>
					{filters.map(({ gridWrapper, ...input }, index) => (
						<Grid
							item
							xs={12}
							sm={input.name === 'ingredient_and_strengths' ? 12 : 6}
							md={gridWrapper}
							key={index}
						>
							<Box>
								<CustomInput
									{...input}
									onChange={onChangeHandler}
									value={searchInputs[input.name]}
									parentValue={parentValue}
								/>
							</Box>
						</Grid>
					))}
					{additionalFilters}
					{compact && (
						<Grid ml='auto' item xs={12} md={gridWidth} display={'flex'}>
							<Box ml='auto' display={'flex'} alignItems={'center'}>
								{showReset && (
									<Box>
										<BaseButton
											color='inherit'
											label={'Clear All'}
											onClick={onResetHandler}
											sx={{
												backgroundColor: COLORS.mediumLightGray
											}}
										/>
									</Box>
								)}
								{/* <Box ml={2}>
									<ExpandButton
										onClick={fullScreenChangeHandler}
										color={'inherit'}
										size={MaterialUISizeEnum.SMALL}
										isExpanded={isFullScreen}
										sx={{
											backgroundColor: COLORS.mediumLightGray,
										}}
									/>
								</Box> */}
							</Box>
						</Grid>
					)}
					<Grid item xs={12}>
						{filters
							.filter(
								(e) => ![
									CustomInputTypeEnum.SWITCH,
									CustomInputTypeEnum.TEXT,
									CustomInputTypeEnum.INGREDIENT_STRENGTH_COMBO,
									// CustomInputTypeEnum.TEXT_SEARCH
								].includes(e.type)
							).reduce((acc, val) => {
								searchInputs[val.name] && acc.push(
									val.name === 'search'
										? ({
											value: searchInputs[val.name],
											name: searchInputs[val.name],
											type: val.name,
											color: val.pillColor || 'auto',
										})
										: searchInputs[val.name].map((value) => ({
											...value,
											type: val.name,
											color: val.pillColor || 'auto'
										}))
								);
								return acc;
							}, [])
							.flat()
							.map((input: any) => {
								return (
									<BaseButton
										key={input.name}
										onClick={() => {
											if (input.type === 'countries' && input.id === 999) {
												// if EU countries box gets unselected remove proper countries, not just "eu27" object
												const nonEUCountries = [
													'Albania',
													'Australia',
													'Switzerland',
													'UK',
													'United Kingdom',
													'Moldova',
													'North Macedonia',
													'Montenegro',
													'Serbia',
													'Turkiye',
													'Ukraine',
													'Kosovo',
													'Turkey',
													'Canada'
												];
												onChangeHandler({
													target: {
														name: input.type,
														value: searchInputs[input.type].filter((item) =>
															nonEUCountries.some((e) => e === item.name)
														),
													},
												});
											} else {
												onChangeHandler({
													target: {
														name: input.type,
														value: input.type === 'search'
															? ''
															: searchInputs[input.type].filter(
																(val) => val.id !== input.id
															),
													},
												});
											}
										}}
										size='small'
										fullWidth={false}
										label={input.name}
										sx={{
											mr: 1,
											mb: 1,
											fontSize: '12px',
											fontWeight: 500,
											textTransform: 'unset',
											height: '28px',
											background: input.color,
											'& .MuiSvgIcon-root': {
												color: '#fff',
											},
										}}
										endIcon={<HighlightOffIcon />}
									/>
								);
							})}
					</Grid>
				</Grid>
			</Paper>
		</>
	);
};
