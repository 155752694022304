/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import { getNestedValue } from 'utils';
import { TypographyVariantType } from 'models/types';

export const ListItem = ({
	dataItem,
	item,
	color = 'inherit',
	fontWeight = 400,
	variant = 'body1',
	...props
}) => {
	// Early return if no dataItem is provided
	if (!dataItem) return null;

	// Render a single value if `item.value` is present
	if (item.value)
		return (
			<Typography
				color={color}
				fontWeight={fontWeight}
				variant={variant as TypographyVariantType}
			>
				{item.value}
			</Typography>
		);

	// Extract the list from `dataItem` and ensure it's valid
	const list = getNestedValue(dataItem, item.listFieldPath);
	if (!Array.isArray(list) || !list.length) {
		return (
			<Typography
				color={color}
				fontWeight={fontWeight}
				variant={variant as TypographyVariantType}
			>
				-
			</Typography>
		);
	}

	const uniqueValues = Array.from(
		new Map(
			list.map((val) => {
				const key = getNestedValue(val, item.fieldPath)?.toLowerCase();
				const value = val;
				return [key, value];
			})
		).values()
	);

	// Render the unique values
	return (
		<Fragment>
			{uniqueValues.map((val, index) => (
				<Typography
					key={val?.id || index}
					color={color}
					fontWeight={fontWeight}
					variant={variant as TypographyVariantType}
				>
					{getNestedValue(val, item.fieldPath)}
				</Typography>
			))}
		</Fragment>
	);
};
