import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { StyledLink } from 'components/StyledComponents';
import { COLORS } from 'utils/consts';

interface ProductCellPropsInterface {
  link: string;
  params?: any;
  value: string;
  icon?: ReactNode;
  endIcon?: ReactNode;
  className?: string;
  extraProps?: {
    minSize?: number | string;
    maxSize?: number | string;
	wrap?: boolean;
  };
}

export const ProductLinkCell: FC<ProductCellPropsInterface> = ({
	link,
	params,
	value,
	icon,
	endIcon,
	className,
	extraProps,
	...props
}) => {
	const { pathname, search } = useLocation();
	return (
		<Box
			display='flex'
			component={'span'}
			alignItems='center'
			maxWidth={extraProps?.maxSize || '100%'}
			className={className || ''}
		>
			{icon && (
				<Box mr={1} component='span'>
					{icon}
				</Box>
			)}
			<Box
				display="flex"
				component={'span'}
				alignItems="center"
				overflow="hidden"
				color={COLORS.primary}
			>
				<StyledLink
					to={{ pathname: link, search: params }}
					state={{ from: pathname + search }}
					style={{
						display: 'inline-block',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: extraProps?.wrap ? 'normal' : 'nowrap',
						maxWidth: '100%',
					}}
					title={value}
					{...props}
				>
					<Typography
						component='span'
						color='primary'
						sx={{
							fontSize: 'inherit',
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: extraProps?.wrap ? 'wrap' : 'nowrap',
							fontWeight: 600,
							'&:hover': {
								color: COLORS.primaryDark,
							}
						}}
						variant='body1'
					>
						{value}
					</Typography>
				</StyledLink>
			</Box>
			{endIcon && (
				<Box ml={1} component="span">
					{endIcon}
				</Box>
			)}
		</Box>
	);
};
