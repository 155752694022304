/* eslint-disable react/prop-types */
import React from 'react';

export const WarningIcon = ({ 
	stroke = '#F87227', 
	reverseColors,
	size = 16,
	...props 
} : {
	stroke?: string; 
	reverseColors?: boolean;
	size?: number;
}) => (
	<svg 
		width={size} 
		height={size} 
		viewBox={'0 0 16 16'} 
		fill="none" 
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path 
			d="M8.00001 13.332C10.9448 13.332 12.3333 13.332 14.3333 13.332C13.3333 11.332 10 6.00064 8.00001 2.66797C6 6.33397 3.66667 10.334 2 13.332C3.66667 13.334 5.05523 13.332 8.00001 13.332Z" 
			fill={reverseColors ? 'white' : stroke} 
			stroke={reverseColors ? 'white' : stroke} 
			strokeMiterlimit="10" 
			strokeLinecap="round" 
			strokeLinejoin="round"
		/>
		<path 
			d="M8.00061 6.66602V9.33268M8.00061 11.3327V10.9993" 
			stroke={reverseColors ? stroke : 'white'} 
			strokeMiterlimit="10" 
			strokeLinecap="round" 
			strokeLinejoin="round"
		/>
	</svg>
);