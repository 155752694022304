import React, { FC } from 'react';
import { CustomInputTypeEnum } from 'models/enums/CustomInputTypeEnum';
import {
	BaseSelectInput,
	CountriesSelectInput,
	BaseAutoCompleteInputRemoteData,
	BaseAutoCompleteInputStaticData,
	CountiesAutoCompleteInput,
	BaseSwitchInput,
	BaseInput,
	SearchInput,
	IngredientComboInput,
} from '../';
import { COLORS } from 'utils/consts';

interface CustomInputProps {
	parentValue?: string;
	type: string;
	name: string;
	label: string;
	placeholder: string;
	value: any[];
	data: any[];
	onChange: (x: any) => void;
}

export const CustomInput: FC<CustomInputProps> = ({ type, ...input }) => {
	switch (type) {
		case CustomInputTypeEnum.COUNTRY:
			return <CountriesSelectInput {...input} fullWidth />;
		case CustomInputTypeEnum.COUNTRY_AUTOCOMPLETE_STATIC:
			return <CountiesAutoCompleteInput {...input} fullWidth />;
		case CustomInputTypeEnum.SELECT:
			return <BaseSelectInput {...input} fullWidth />;
		case CustomInputTypeEnum.AUTOCOMPLETE_REMOTE:
			return <BaseAutoCompleteInputRemoteData {...input} fullWidth />;
		case CustomInputTypeEnum.AUTOCOMPLETE_STATIC:
			return <BaseAutoCompleteInputStaticData {...input} fullWidth />;
		case CustomInputTypeEnum.SWITCH:
			return <BaseSwitchInput {...input} />;
		case CustomInputTypeEnum.TEXT:
			return <BaseInput {...input} background={COLORS.white} fullWidth />;
		case CustomInputTypeEnum.TEXT_SEARCH:
			return <SearchInput {...input} fullWidth />;
		case CustomInputTypeEnum.INGREDIENT_STRENGTH_COMBO:
			return <IngredientComboInput {...input} fullWidth />;
		default:
			return null;
	}
};
