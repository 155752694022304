import React from 'react';
import { CardItemTypeEnum } from 'models/enums';

export const cardFields = [
	{
		key: 'Product Name:',
		fieldPath: ['pack', 'product', 'name'],
		type: CardItemTypeEnum.TEXT,
		capitalize: true,
		isBriefField: true,
	},
	{
		key: 'MA Holder:',
		fieldPath: ['pack', 'product', 'company', 'name'],
		linkFieldPath: ['pack', 'product', 'company', 'id'],
		type: CardItemTypeEnum.TEXT,
		linkBase: 'company-explorer/',
		isBriefField: true
	},
	{ 
		key: 'EU Number:', 
		fieldPath: ['code'], 
		type: CardItemTypeEnum.TEXT,
		isBriefField: true
	},
	{
		key: 'ATC Code:',
		fieldPath: ['pack', 'product', 'atc_code', 'name'],
		additionalFieldPath: ['pack', 'product', 'atc_code', 'code'],
		linkFieldPath: ['pack', 'product', 'atc_code', 'id'],
		devider: '',
		type: CardItemTypeEnum.LINK,
		linkBase: 'atc-code-explorer/',
	},
	{
		key: 'First Registered:',
		fieldPath: ['date_of_registration'],
		type: CardItemTypeEnum.DATE,
		mandatory: 'date_of_registration',
	},
	{
		key: 'Active Ingredients:',
		listFieldPath: ['pack', 'active_ingredients'],
		fieldPath: ['active_ingredient', 'name'],
		linkFieldPath: ['active_ingredient', 'id'],
		type: CardItemTypeEnum.LIST_LINK,
		linkBase: 'ingredients/',
		capitalize: true,
	},
	{
		key: 'Country Registered:',
		fieldPath: ['country', 'name'],
		value: 'European Union',
		type: CardItemTypeEnum.TEXT,
	},
];
