import { Grid, Tooltip, Typography } from '@mui/material';
import { LinkArrowSquareIcon, WarningIcon } from 'components/Icons';
import { HeaderTitle, StyledBtnLink } from 'components/StyledComponents';
import React from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from 'utils/consts';

export const CustomHeader = ({
	productDetails,
	shortageInfo,
}: {
	productDetails?: any;
	shortageInfo?: any;
}) => {
	return (
		<Grid container justifyContent='space-between' alignItems='flex-start' gap='10px'>
			<Grid 
				item 
				container
				lg={productDetails?.link_to_source ? 9.5 : 12}
				xs={productDetails?.link_to_source ? 8 : 12}
				sx={{
					columnGap: '20px',
					alignItems: 'flex-start',
					flexWrap: { xs: 'wrap', lg: 'nowrap' },
				}}
			>
				<Grid item>
					<HeaderTitle component='h1'>
						{productDetails?.pack?.product?.name}
					</HeaderTitle>
				</Grid>
				{shortageInfo && (
					<Grid 
						item 
						container
						sx={{
							flexWrap: 'nowrap',
							columnGap: '10px',
							alignItems: 'stretch',
							flexBasis: 'content !important',
						}}
					>
						<Grid 
							item 
							container 
							sx={{
								background: COLORS.warning, 
								columnGap: '10px',
								borderRadius: '8px',
								flexWrap: 'nowrap',
								alignItems: 'center',
								justifyContent: 'center',
								width: '150px'
							}}
						>
							<WarningIcon size={22} reverseColors />
							<Typography color={COLORS.white} whiteSpace={'nowrap'}>
								In shortage
							</Typography>
						</Grid>
						{shortageInfo?.link && (
							<Tooltip title='View shortage detail'>
								<Link 
									to={shortageInfo?.link}
									target='_blank'
									style={{
										backgroundColor: COLORS.mediumTransparent,
										color: '#000',
										textDecoration: 'none',
										borderRadius: '10px',
										textAlign: 'center',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										width: '44px',
										height: '44px'
									}}
								>
									<LinkArrowSquareIcon stroke="#000" />
								</Link>
							</Tooltip>
						)}
					</Grid>
				)}
			</Grid>
			{productDetails?.link_to_source && (
				<StyledBtnLink 
					to={productDetails?.link_to_source}
					target='_blank'
					sx={{
						padding: '15px',
						display: 'flex',
						columnGap: '10px'
					}}
				>
					<LinkArrowSquareIcon stroke="#fff" />
					<Typography variant='subtitle2' textTransform={'uppercase'}>
						{shortageInfo ? 'View EMA page' : 'View source'}
					</Typography>
				</StyledBtnLink>
			)}
		</Grid>
	);
};
