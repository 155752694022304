import React from 'react';
import { CardItemTypeEnum } from 'models/enums';

export const cardFields = [
	{
		key: 'Product Name:',
		fieldPath: ['pack', 'product', 'name'],
		type: CardItemTypeEnum.TEXT,
		capitalize: true,
		isBriefField: true
	},
	{
		key: 'MA Holder:',
		fieldPath: ['pack', 'product', 'company', 'name'],
		linkFieldPath: ['pack', 'product', 'company', 'id'],
		type: CardItemTypeEnum.TEXT,
		linkBase: 'company-explorer/',
		isBriefField: true
	},
	{
		key: 'Active Ingredients:',
		listFieldPath: ['pack', 'active_ingredients'],
		fieldPath: ['active_ingredient', 'name'],
		linkFieldPath: ['active_ingredient', 'id'],
		type: CardItemTypeEnum.LIST_LINK,
		linkBase: 'ingredients/',
		capitalize: true,
	},
	{
		key: 'Strength:',
		type: CardItemTypeEnum.LIST,
		listFieldPath: ['pack', 'active_ingredients'],
		fieldPath: ['strength', 'name'],
	},
	{
		key: 'ATC Code:',
		fieldPath: ['pack', 'product', 'atc_code', 'name'],
		additionalFieldPath: ['pack', 'product', 'atc_code', 'code'],
		linkFieldPath: ['pack', 'product', 'atc_code', 'id'],
		devider: '',
		type: CardItemTypeEnum.LINK,
		linkBase: 'atc-code-explorer/',
	},
	{
		key: 'Pack Size:',
		fieldPath: ['pack', 'pack_size', 'name'],
		type: CardItemTypeEnum.TEXT,
	},
	{
		key: 'Presentation:',
		fieldPath: ['pack', 'presentation', 'name'],
		type: CardItemTypeEnum.TEXT,
	},
	{
		key: 'First Registered:',
		fieldPath: ['date_of_registration'],
		type: CardItemTypeEnum.DATE,
		mandatory: 'date_of_registration',
	},
	{
		key: 'Administration Route:',
		listFieldPath: ['pack', 'routes'],
		fieldPath: ['name'],
		type: CardItemTypeEnum.LIST,
		mandatoryFieldPath: ['pack', 'routes'],
	},
	{
		key: 'Country Registered:',
		fieldPath: ['country', 'name'],
		type: CardItemTypeEnum.TEXT,
		isBriefField: true
	},
	{
		key: 'National Registration Number:',
		fieldPath: ['code'],
		type: CardItemTypeEnum.TEXT,
		isBriefField: true
	},
	{
		key: 'Registration Type:',
		fieldPath: ['registration_type'],
		type: CardItemTypeEnum.TEXT,
		mandatory: 'registration_type',
	},
];
