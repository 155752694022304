import React, { ReactNode, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { StyledLink } from 'components/StyledComponents';

interface PriceHistoryLinkCellProps {
  link: string;
  value?: string;
  icon?: ReactNode;
  dataItem: any;
}

export const PriceHistoryLinkCell = ({
	link,
	icon,
	dataItem
}: PriceHistoryLinkCellProps) => {

	const url = useMemo(()=>{
		return { pathname: window.location.pathname, search: window.location.search ? `${window.location.search}&${link}` : `?${link}` };
	},[window.location.search, window.location.pathname]);

	return (
		<StyledLink
			// @ts-ignore
			disabled={!dataItem?.is_price_available}
			to={url}
		>
			{icon && <Box component='span'>{icon}</Box>}
		</StyledLink>
	);
};
