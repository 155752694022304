/* eslint-disable react/prop-types */
import React from 'react';

export const SortDownIcon = ({ stroke = '#000', ...props }) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none' {...props}>
		<path
			d='M12.3564 8.62883L8.54778 12.4375L4.73911 8.62883M8.54778 3.5615V12.4262'
			stroke={stroke}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
