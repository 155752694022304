/* eslint-disable react/prop-types */
import React from 'react';

export const SortUpwardIcon = ({ stroke = '#000', ...props }) => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' stroke={stroke} {...props}>
		<path
			d='M4.19933 7.37117L8.008 3.5625L11.8167 7.37117M8.008 12.4385V3.57383'
			stroke={stroke}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
